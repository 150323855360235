import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

// Populate using values in copy-paste JavaScript snippet.
const options = {
  init: { 
    distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: ["bam.nr-data.net"] }
   }, // NREUM.init
  info: { 
    beacon: "bam.nr-data.net", errorBeacon: "bam.nr-data.net", licenseKey: "NRJS-e82dd08a64f582cfaf6", applicationID: "1589065748", sa: 1 
  }, // NREUM.info
  loader_config: { 
    accountID: "6584445", trustKey: "6584445", agentID: "1589065748", licenseKey: "NRJS-e82dd08a64f582cfaf6", applicationID: "1589065748" 
  } // NREUM.loader_config
}

// The agent loader code executes immediately on instantiation.
export default function initNewRelic() {
  new BrowserAgent(options)
}
