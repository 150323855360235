import $ from 'jquery'
import { isEmpty, isNil, isFunction } from 'lodash'
import BulkOrderForm from '../classes/BulkOrderForm'
import '../assets/css/fpd-bulk-order-form.scss'
/**
 * Bulk Order Operator
 * 
 * - Orders multiple variants of a customized product
 * - uses the atc operator to add the products to the cart
 * - shows the order form for the different variants
 */
export default class BulkOrderOperator {
  constructor(productPage) {
    this.productPage = productPage
    this.atcOperator = productPage.atcOperator
    this.bulkOrderContainer = null
    this.optionsManager = productPage.optionsManager
    this.quantitySelector = productPage.taProduct.quantityContainer || ".product-form__quantity"
    this.imageUpdateTimeout = null
  }

  isBulkOrder() {
    this.bulkOrderContainer = document.querySelector('#fpd-bulk-order-container')
    return !isNil(this.bulkOrderContainer)
  }

  initListener() {
    this.initBulkOrderForm()
    this.hideNonBulkElements()
    this.imageUpdateListener()
  }

  initBulkOrderForm() {
    this.bulkOrderForm = new BulkOrderForm(
      this.productPage.fpdManager.instance,
      this.bulkOrderContainer,
      this.productPage.getShopifyProduct(),
      this.addBulkOrderToCart.bind(this),
      this.afterAtcCallback.bind(this)
    )
    this.bulkOrderForm.initBulkOrderForm()
    $(document).on('fpd:price:change', () => this.bulkOrderForm.updatePrice())
  }

  imageUpdateListener() {
    $(document).on('fpd:preview', () => {
      if(this.imageUpdateTimeout) {
        clearTimeout(this.imageUpdateTimeout)
      }
      this.imageUpdateTimeout = setTimeout(() => {
        this.productPage.licManager.createPreviewImage().then((createdImage) => {
          this.bulkOrderForm.updatePreviewImage(createdImage)
        })
      }, 1000)
    })
  }

  hideNonBulkElements() {
    const $atcButtons = this.productPage.atcOperator.getAtcButtons()
    if ($atcButtons) {
      $atcButtons.hide()
    }
    const quantityContainer = document.querySelector(this.quantitySelector)
    if (quantityContainer) {
      quantityContainer.style.display = "none"
    }
  }

  async addBulkOrderToCart() {
    const shopifyProduct = this.productPage.getShopifyProduct()
    const variants = this.bulkOrderForm.selectedVariants()

    const lineItemCache = await this.productPage.licManager.processLicCreation(shopifyProduct, variants, false)
    let properties = {
      '_fpd-hash': lineItemCache.hash,
      '_fpd-url': lineItemCache.url,
      '_fpd-handle': shopifyProduct.handle
    }

    if (this.optionsManager.hasCallback('cartTextPropertiesPost')) {
      properties = this.optionsManager.invokeCallback('cartTextPropertiesPost', properties, lineItemCache, shopifyProduct)
    }

    const atcItems = []
    this.bulkOrderForm.selectedVariantsWithQuantity().forEach((input) => {
      if (input.quantity == 0) return

      let variantId = parseInt(input.id)
      if (lineItemCache.custom_variants.length > 1) {
        const orgVariant = this.productPage.getShopifyProduct().variants.find((variant) => variant.id == variantId)
        const customVariant = lineItemCache.custom_variants.find((v) => 
          orgVariant.option1 == v.option1 &&
          orgVariant.option2 == v.option2 &&
          orgVariant.option3 == v.option3
        )
        console.debug("orgVariant", orgVariant, "custom variant", customVariant)
        if(!isNil(customVariant)) {
          variantId = customVariant.id
        }
      }
      
      const item = {
        id: variantId,
        quantity: input.quantity,
        properties: properties
      }
      atcItems.push(item)
    })

    if (isEmpty(atcItems)) return

    fetch(window.Shopify.routes.root + 'cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ items: atcItems })
    })
      .then(response => (response.json())
        .then(data => {
          if (data.status == 422) {
            console.debug("Unable to add to cart:", data)
            this.message = data.description
            return this.renderSummeryFooter()
          }

          this.message = ""

          this.afterAtcCallback(data)
        }))
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  afterAtcCallback(data) {
    this.bulkOrderForm.enableBulkOrderAtcButton()
    if(this.optionsManager.hasCallback('addProductToForm')) {
      this.optionsManager.invokeCallback('addProductToForm', this.productPage.$addToCartForm, data)
    } else {
      window.location.href = '/cart'
    }
  }
}